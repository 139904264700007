import { format } from 'date-fns'
import { intervalToDuration, formatDuration } from 'date-fns'

export const useDateFunctions = () => {
    const { $lang } = useNuxtApp()

    // Receive a number and return a string with ',' every 3 digits
    const numberFormattedWithCommas = (number: number) => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    const getExpireMessage = (date: string) => {
        const expireMessages = [
            'Finaliza HOY',
            'Finaliza MAÑANA',
            'Finaliza en [days] dias',
            'Finaliza en 1 semana',
            'Finaliza el [date]',
        ]

        const now = new Date().getTime()
        const time = new Date(date).getTime()
        const days = Math.ceil((time + 2 - now) / (1000 * 60 * 60 * 24))

        let message = expireMessages[days] || ''
        let isDanger = false

        if (days > 1 && days < 7) message = expireMessages[2].replace('[days]', String(days))

        if (days > 7) {
            const expire = format(time, 'dd/MM/yyyy') + ' a las ' + format(time, 'hh:ss') + 'h'
            message = expireMessages[4].replace('[date]', expire)
        }

        if (days === 7) message = expireMessages[3]
        if (days <= 3) isDanger = true

        return { message, isDanger }
    }

    const createFormatDate = (date: string, hours = false) => {
        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            timeZone: $lang.timeZone,
        }

        if (hours) {
            options.hour = 'numeric'
            options.minute = 'numeric'
            options.hour12 = false
        }

        return new Intl.DateTimeFormat('en-US', options).format(new Date(date))
    }

    const joinDateToString = (dateFormat: { date: string; time: string }): string => {
        const { date, time } = dateFormat
        if (!date) return ''
        const dateString = createFormatDate(date) + ' ' + time
        return new Date(dateString).toISOString()
    }

    // <object> Return the current date
    const currentDate = () => {
        const state = new Date()
        const months = $lang.lib.date_functions.months
        return {
            month: months[state.getMonth()],
            year: state.getFullYear(),
            day: state.getDate(),
        }
    }

    // <string> Return the date difference and format it
    const getDateDifference = (date: string | number | Date) => {
        const duration = intervalToDuration({
            start: new Date(date),
            end: new Date(),
        })

        const formatDistanceLocale = {
            xSeconds: '{{count}} ' + $lang.lib.date_functions.times.sec,
            xMinutes: '{{count}} ' + $lang.lib.date_functions.times.min,
            xHours: '{{count}} ' + $lang.lib.date_functions.times.hs,
            xDays: '{{count}} ' + $lang.lib.date_functions.times.day,
            xWeeks: '{{count}} ' + $lang.lib.date_functions.times.week,
            xMonths: '{{count}} ' + $lang.lib.date_functions.times.month,
            xYears: '{{count}} ' + $lang.lib.date_functions.times.year,
        } as { [x: string]: any }

        const shortEsLocale = {
            formatDistance: (token: any, count: any) =>
                formatDistanceLocale[token].replace('{{count}}', count),
        }

        const units = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
        const nonzero = Object.entries(duration)
            .filter(([_, value]) => value)
            .map(([unit, _]) => unit)

        return formatDuration(duration, {
            format: units.filter((i) => new Set(nonzero).has(i)).slice(0, 1) as any,
            delimiter: ', ',
            locale: shortEsLocale,
        })
    }

    const formatDate = (date: any, join_method: any, hours = false) => {
        try {
            const method =
                join_method === '/'
                    ? ({
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                      } as Intl.DateTimeFormatOptions)
                    : ({
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                      } as Intl.DateTimeFormatOptions)
            const createDate = new Date(date)
            if (hours) {
                return new Intl.DateTimeFormat($lang.lib.date_functions.datetimeFormat, {
                    hour: 'numeric',
                    minute: 'numeric',
                }).format(createDate)
            }
            return new Intl.DateTimeFormat($lang.lib.date_functions.datetimeFormat, method)
                .format(createDate)
                .split(' ')
                .join(join_method)
        } catch (error) {
            return null
        }
    }

    const formatDatAndMonth = (date: any) => {
        try {
            const options = {
                timeZone: $lang.lib.date_functions.timezone,
            }

            const createDate = new Date(date)

            return new Intl.DateTimeFormat($lang.lib.date_functions.datetimeFormat, options).format(
                createDate,
            )
        } catch (error) {
            return null
        }
    }
    return {
        numberFormattedWithCommas,
        getExpireMessage,
        createFormatDate,
        joinDateToString,
        getDateDifference,
        formatDate,
        formatDatAndMonth,
        currentDate,
    }
}
