export const textShortener = (text: string, maxLength: number) => {
    if (!text) return ''

    let joined = ''

    const splitted = text.split(' ')

    for (const word of splitted) {
        const suspensivePoints = joined.charAt(joined.length - 1) === '.' ? '..' : '...'

        if (joined.length + word.length + suspensivePoints.length > maxLength) {
            joined = joined.concat(suspensivePoints)

            break
        }

        joined = joined.concat(' ' + word)
    }

    return (joined.charAt(joined.length - 1) === '.' ? joined : joined.concat('.')).trim()
}

export const replacePlaceholders = (placeholders: [string, string][], raw: string | string[]) => {
    if (typeof raw === 'string') {
        return placeholders.reduce(
            (text, placeholders) => text?.replace(new RegExp(placeholders[0], 'gi'), placeholders[1]),
            raw,
        )
    }

    if (raw instanceof Array) {
        return raw.map((text) => {
            return placeholders.reduce((text, placeholder) => {
                return text?.replace(new RegExp(placeholder[0], 'gi'), placeholder[1])
            }, text)
        })
    }

    return ''
}

export function removeXSS(str: string | null): string | null {
    if (str) {
        return str.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, ' ')
    } else return null
}

export function setURLS(text: string) {
    const urlRegex =
        /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:;%_+.~#?&//=]*)/gi
    return text.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="${
            url.includes(window.location.host) ? 'follow' : 'nofollow'
        }">${url}</a>`
    })
}
